import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout/layout";
import { getBlock, parseStory } from "../components/utils";

const Project = ({ data }) => {
  const p = parseStory(data.storyblokEntry).content;
  return (
    <Layout>
      <div className="py-6 md:py-12 as-cont">
        <h1 className="as-h1 flex justify-between items-center">{p.title}</h1>
        <div className="h-0.5 my-4 w-2/3 bg-slate-300" />
        {(p?.body ?? []).map((block) => getBlock(block))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      content
      slug
    }
  }
`;

export default Project;
